<template>
    <div class="card mb-4">
        <h5 class="card-header">Projekty użytkownika</h5>
        <div class="table- mb-3">
            <div id="DataTables_Table_0_wrapper" class="dataTables_wrapper dt-bootstrap5 no-footer">

                <table class="table datatable-project border-top dataTable no-footer dtr-column" id="DataTables_Table_0"
                    aria-describedby="DataTables_Table_0_info" style="width: 918px;">
                    <thead>
                        <tr>
                            <th class="control sorting dtr-hidden" tabindex="0" aria-controls="DataTables_Table_0"
                                rowspan="1" colspan="1" style="width: 0px; display: none;"
                                aria-label=": activate to sort column ascending">
                            </th>
                            <th class="sorting sorting_desc" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1"
                                colspan="1" style="width: 350px;" aria-label="Project: activate to sort column ascending"
                                aria-sort="descending">Projekt</th>
                            <th class="text-nowrap sorting_disabled" rowspan="1" colspan="1" style="width: 149px;"
                                aria-label="Total Task">Liczba zadań</th>
                            <th class="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1"
                                style="width: 140px;" aria-label="Progress: activate to sort column ascending">Postęp</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="odd" v-for="project, index in projects" :key="index">
                            <td class="  control" tabindex="0" style="display: none;"></td>
                            <td class="sorting_1">
                                <div class="d-flex justify-content-left align-items-center">
                                    <div class="avatar-wrapper">
                                        <div class="avatar avatar-sm me-3"><img
                                                :src="getUser(project.projectuser_id).avatar" alt="Project Image"
                                                class="rounded-circle"></div>
                                    </div>
                                    <div class="d-flex flex-column"><span class="text-truncate fw-semibold">{{
                                        project.project_name }}</span><small class="text-muted">{{
        project.project_shortname }}</small>
                                    </div>
                                </div>
                            </td>
                            <td>{{ project.tasks_count_open }}/{{ project.tasks_count }}</td>
                            <td>
                                <div class="d-flex flex-column"><small class="mb-1">{{ countProgress(project) }}%</small>
                                    <div class="progress w-100 me-3" style="height: 6px;">
                                        <div class="progress-bar bg-success"
                                            :style="'width: ' + countProgress(project) + '%'" aria-valuemin="0"
                                            aria-valuemax="100"></div>
                                    </div>
                                </div>
                            </td>

                        </tr>
                    </tbody>
                </table>

            </div>
        </div>
    </div>
</template>
<script>
import axios from '../../../api/axios';

export default {
    props: ['user_id'],
    data: function () {
        return {
            projects: [],
        }
    },
    created: function () {
        this.getProjects();
    },
    methods: {
        countProgress(project) {
            if (Number(project.tasks_count) == 0) return 100;
            return Math.round(Number(project.tasks_count_open) / Number(project.tasks_count) * 100)
        },
        getUser(id) {
            var name = { name: 'nie przypisano', avatar: "/defaultuser.png" };
            this.$store.state.slownik_user.forEach(function (item) {
                if (item.user_id == id) {
                    name = item;
                }
            })
            return name;
        },
        getProjects: function () {
            axios.get('api/v1/user/users/' + this.user_id + '/projects?limit=15').then((response) => {
                this.projects = response.data.data;
            })

        }
    }
}
</script>