<template>
    <!-- Activity Timeline -->
    <div class="card mb-4">
        <h5 class="card-header">Oś czasu użytkownika</h5>
        <div class="card-body">
            <ul class="timeline">

                <li class="timeline-item timeline-item-transparent" v-for="post, index in posts" :key="index">
                    <span class="timeline-point timeline-point-success"></span>
                    <div class="timeline-event">
                        <div class="timeline-header mb-1">
                            <!-- <h6 class="mb-0">Design Review</h6> -->
                            <small class="text-muted">{{ post.message }}</small>
                        </div>
                        <p class="mb-0">{{ post.updated_at }}</p>
                    </div>
                </li>
                <li class="timeline-end-indicator">
                    <i class="bx bx-check-circle"></i>
                </li>
            </ul>
        </div>
    </div>
    <!-- /Activity Timeline -->

    <!--/ User Content -->
</template>
<script>
import axios from '../../../api/axios';
export default {
    props: ['user_id'],
    data: function () {
        return {
            posts: [],
        }
    },
    created: function () {
        this.getPosts();
    },
    methods: {
        getPosts: function () {
            axios.get('api/v1/user/users/' + this.user_id + '/logs?limit=15').then((response) => {
                this.posts = response.data.data;
            })

        }
    }
}
</script>