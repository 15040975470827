<template>
  <div class="container-xxl flex-grow-1 container-p-y">

    <h4 class="fw-bold py-3 mb-4">
      <span class="text-muted fw-light">Użytkownik / </span> Konto
    </h4>
    <div class="row">
      <!-- User Sidebar -->
      <div class="col-xl-4 col-lg-5 col-md-5 order-1 order-md-0">
        <!-- User Card -->

        <UserCardDetail :user="user" :key="user.id"></UserCardDetail>
        <!-- /User Card -->

      </div>
      <!--/ User Sidebar -->


      <!-- User Content -->
      <div class="col-xl-8 col-lg-7 col-md-7 order-0 order-md-1">
        <!-- User Pills -->
        <ul class="nav nav-pills flex-column flex-md-row mb-3">
          <li class="nav-item"><router-link class="nav-link active" :to="'/user/' + user.id"><i
                class="bx bx-user me-1"></i>Konto</router-link></li>
          <li class="nav-item"><router-link class="nav-link" :to="'/user/' + user.id + '/secuity'"><i
                class="bx bx-lock-alt me-1"></i>Bezpieczeństwo</router-link></li>
          <li class="nav-item"><router-link class="nav-link" :to="'/user/' + user.id + '/notifications'"><i
                class="bx bx-bell me-1"></i>Powiadomienia</router-link></li>
        </ul>
        <!--/ User Pills -->

        <!-- Project table -->
        <UserProjectsList v-if="user_id" :user_id="user_id"></UserProjectsList>

        <!-- /Project table -->

        <UserTimeline :user_id="user_id"></UserTimeline>

        <!-- Modal -->
        <!-- Edit User Modal -->
        <EditUserInformation></EditUserInformation>
      </div>
    </div>
    <!--/ Edit User Modal -->

    <!-- Add New Credit Card Modal -->
    <div class="modal fade" id="upgradePlanModal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-simple modal-upgrade-plan">
        <div class="modal-content p-3 p-md-5">
          <div class="modal-body">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            <div class="text-center mb-4">
              <h3>Upgrade Plan</h3>
              <p>Choose the best plan for user.</p>
            </div>
            <form id="upgradePlanForm" class="row g-3" onsubmit="return false">
              <div class="col-sm-9">
                <label class="form-label" for="choosePlan">Choose Plan</label>
                <select id="choosePlan" name="choosePlan" class="form-select" aria-label="Choose Plan">
                  <option selected="">Choose Plan</option>
                  <option value="standard">Standard - $99/month</option>
                  <option value="exclusive">Exclusive - $249/month</option>
                  <option value="Enterprise">Enterprise - $499/month</option>
                </select>
              </div>
              <div class="col-sm-3 d-flex align-items-end">
                <button type="submit" class="btn btn-primary">Upgrade</button>
              </div>
            </form>
          </div>
          <hr class="mx-md-n5 mx-n3">
          <div class="modal-body">
            <h6 class="mb-0">User current plan is standard plan</h6>
            <div class="d-flex justify-content-between align-items-center flex-wrap">
              <div class="d-flex justify-content-center me-2 mt-3">
                <sup class="h5 pricing-currency pt-1 mt-3 mb-0 me-1 text-primary">$</sup>
                <h1 class="display-3 mb-0 text-primary">99</h1>
                <sub class="h5 pricing-duration mt-auto mb-2">/month</sub>
              </div>
              <button class="btn btn-label-danger cancel-subscription mt-3">Cancel Subscription</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--/ Add New Credit Card Modal -->

    <!-- /Modal -->

  </div>
</template>
<script>
import axios from '../../api/axios';
import UserCardDetail from './components/UserCardDetail.vue';
import EditUserInformation from './components/EditUserInformation.vue';
import UserProjectsList from './components/UserProjectsList.vue';
import UserTimeline from './components/UserTimeline.vue';

export default {
  components: {
    UserCardDetail,
    EditUserInformation,
    UserProjectsList,
    UserTimeline
  },
  data: function () {
    return {
      avatar: '',
      user: { id: 1 },
      user_id: 0,
      validation: '',
      success: false,
    }
  },
  created: function () {
    this.user_id = this.$route.params.id;
    axios.get('api/v1/user/users/' + this.$route.params.id).then((response) => {
      this.user = response.data;
    })

  },
  methods: {
  },

}
</script>
